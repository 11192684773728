const uniteList = {
  "TYPES" : [
    {
      Value : 'M',
      Title : 'Metre',
      Shorthand : 'm',
    },
    {
      Value : 'M2',
      Title : 'Metrekare',
      Shorthand: 'm &#178;',
    },
    {
      Value : 'M3',
      Title : 'Metreküp',
      Shorthand: 'm &#13221;',
    },
    {
      Value : 'KG',
      Title : 'Kilogram',
      Shorthand: 'kg',
    },
    {
      Value : 'PIECE',
      Title : 'Adet',
      Shorthand: 'Piece',
    },
    {
      Value : 'PAX',
      Title : 'Kişi',
      Shorthand: 'Pax',
    },
    {
      Value : 'Times',
      Title : 'Sefer',
      Shorthand: 'Times',
    }],
};
export default uniteList;
