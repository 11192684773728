
import {computed, defineComponent, ref} from "vue";
import {Field, Form} from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import curList from "@/core/data/currency";
import unitList from "@/core/data/units";


interface newItemData {
  itemTitle: string;
  itemList: string;
  itemCost: string;
  itemCostCur: string;
  itemPriceTRY: string;
  itemPriceEUR: string;
  itemPriceUSD: string;
  itemPriceSTG: string;
  itemSupplier: string;
  itemUnit: string;
  itemDescription: string;
  itemLink: string;
}

export default defineComponent({
  name: "HotelSpec",
  components: {
    Field,
    Form,
  },
  props: {
    widgetClasses: String,
    listData: Object,
  },
  data: function () {
    return {}
  },
  methods: {},
  setup(props) {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const newItemData = ref<newItemData>({
      itemTitle: "",
      itemList: "",
      itemCost: "",
      itemCostCur: "",
      itemPriceTRY: "",
      itemPriceEUR: "",
      itemPriceUSD: "",
      itemPriceSTG: "",
      itemSupplier: "",
      itemUnit: "",
      itemDescription: "",
      itemLink: "",
    });

    const myListDetails = computed(() => {
      return store.getters.selectedPriceLists;
    });

    const payload1 = {
      PriceListID: props.listData.ID,
    }

    //store.dispatch(Actions.PRICE_LIST_ITEMS_LIST, payload1);
    const myPriceList = computed(() => {
      return store.getters.priceListItemsSelectedList;
    });
    store.dispatch(Actions.SUPPLIERS_LIST_ALL, {});
    const mySupplierList = computed(() => {
      return store.getters.suppliersList;
    });

    const mySupplierTitle = computed(() => {
      let tmpArray = [];
      if (mySupplierList.value.data !== undefined) {
        for (var i of mySupplierList.value.data) {
          tmpArray.push(i.Title);
        }
      }
      return tmpArray;
    });


    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }
      if (!newItemData.value.itemTitle) {
        Swal.fire({
          text: "Lütfen 'Hizmet' bilgisini Giriniz",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        return;
      }
      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      const payload1 = {
        Title: newItemData.value.itemTitle,
        PriceListID: props.listData.ID,
        Cost: newItemData.value.itemCost,
        CostCur: newItemData.value.itemCostCur,
        PriceTRY: newItemData.value.itemPriceTRY,
        PriceEUR: newItemData.value.itemPriceEUR,
        PriceUSD: newItemData.value.itemPriceUSD,
        PriceSTG: newItemData.value.itemPriceSTG,
        SupplierID: (mySelectedSupplier[0] !== undefined) ? mySelectedSupplier[0]['ID'] : '',
        Unit: newItemData.value.itemUnit,
        Description: newItemData.value.itemDescription,
        Link: newItemData.value.itemLink,
      }
      console.log(payload1);

      store.dispatch(Actions.PRICE_LIST_ITEMS_INSERT, payload1)
          .then(() => {
            Swal.fire({
              text: "Tebrikler. Başarılı bir şekilde ekleme işlemini tamamladınız.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Geri Dön!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              submitButtonRef.value.disabled = false;
              submitButtonRef.value.setAttribute("data-kt-indicator", "off");

            });
          }).catch(() => {
        Swal.fire({
          text: store.getters.getPriceListItemsErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        submitButtonRef.value.disabled = false;
        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
      });
    };

    const update = (key) => {

      if (!myPriceList.value.data[key].Title) {
        Swal.fire({
          text: "Lütfen 'Hizmet' bilgisini Giriniz",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        return;
      }
      //Disable button

      // Activate indicator
      let idBtn = "btnItemUpdate" + key;
      let button = document.getElementById(idBtn) as HTMLElement;
      button.setAttribute('data-kt-indicator', 'on');

      let tmpSelectedSupplier = setSupplier(key);
      const payload1 = {
        ID: myPriceList.value.data[key].ID,
        Title: myPriceList.value.data[key].Title,
        PriceListID: props.listData.ID,
        Cost: myPriceList.value.data[key].Cost,
        CostCur: myPriceList.value.data[key].CostCur,
        PriceTRY: myPriceList.value.data[key].PriceTRY,
        PriceEUR: myPriceList.value.data[key].PriceEUR,
        PriceUSD: myPriceList.value.data[key].PriceUSD,
        PriceSTG: myPriceList.value.data[key].PriceSTG,
        SupplierID: (tmpSelectedSupplier) ? tmpSelectedSupplier[0].ID : 'NULL',
        Unit: myPriceList.value.data[key].Unit,
        Description: myPriceList.value.data[key].Description,
        Link: myPriceList.value.data[key].Link,
      }
      console.log(payload1);

      store.dispatch(Actions.PRICE_LIST_ITEMS_EDIT, payload1)
          .then(() => {
            Swal.fire({
              text: "Tebrikler. Başarılı bir şekilde güncelleme işlemini tamamladınız.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Geri Dön!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              button.setAttribute('data-kt-indicator', 'off');
            });
          }).catch(() => {
        Swal.fire({
          text: store.getters.getPriceListItemsErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        button.setAttribute('data-kt-indicator', 'off');
      });

    };

    const updateActive = (ID, Active) => {
      const payload = {
        ID: ID,
        is_active: (Active == 'active') ? "passive" : "active",
        PriceListID: props.listData.ID,
      }
      store.dispatch(Actions.PRICE_LIST_ITEMS_EDIT, payload)
    }
    const deleteItem = (ID) => {
      Swal.fire({
        title: 'Emin misiniz?',
        text: "Kaydi silmek işlemi geri alınamaz.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: 'Kaydı Sil',
        cancelButtonText: `Vazgeç`,
        confirmButtonColor: '#9c1d1d',
        cancelButtonColor: '#3085d6',
      }).then(function (result) {
        if (result.isConfirmed) {
          const payload = {
            ID: ID,
            PriceListID: props.listData.ID,
          }
          store.dispatch(Actions.PRICE_LIST_ITEMS_DELETE, payload)
              .then(() => {
                Swal.fire(
                    'Silindi!',
                    'Kayit silme işlemi tamamlandı.',
                    'success'
                )
              }).catch(() => {
            Swal.fire({
              text: store.getters.getPriceListItemsErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Tekrar dene!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        }
      });

    }
    let mySelectedSupplier = [];
    const setNewSupplier = (e) => {
      mySelectedSupplier = mySupplierList.value.data.filter(item =>
          !(item.Title).indexOf(e)
      );
      return true;
    };

    const setSupplier = (key) => {
      if (mySupplierList.value.data != null) {
        let mySupplier = document.getElementById("itemSupplier" + key) as HTMLInputElement;
        if (mySupplier.value != '') {
          return mySupplierList.value.data.filter(item =>
              !(item.Title).indexOf(mySupplier.value)
          );
        } else {
          return false
        }
      } else {
        return false;
      }
    };
    const orderSpec = (key, Action) => {
      const payload = {
        ID: myPriceList.value.data[key].ID,
        Action: Action,
        PriceListID: props.listData.ID,
      }
      store.dispatch(Actions.PRICE_LIST_ITEMS_ORDER, payload)
    }
    return {
      newItemData,
      curList,
      unitList,
      myPriceList,
      mySupplierList,
      mySupplierTitle,
      setNewSupplier,
      setSupplier,
      submit,
      update,
      updateActive,
      deleteItem,
      submitButtonRef,
      orderSpec,
    };
  },
});
