
import {computed, defineComponent, onMounted} from "vue";
import PriceListItems from "@/components/mice/pricelists/PriceListItems.vue";

import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {useRoute} from "vue-router";

export default defineComponent({
  name: "overview",
  components: {
    PriceListItems,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Fiyat Listesi Detayları", ["Fiyat Listeleri"]);
    });

    const route = useRoute();
    const id = route.params.id;
    const payload = {
      ID: id,
    }
    //console.log(payload);
    store.dispatch(Actions.PRICE_LISTS_DETAILS, payload);
    const myDetails = computed(() => {
      return store.getters.priceListsDetails;
    });
    const payload1 = {
      PriceListID: id,
    }

    store.dispatch(Actions.PRICE_LIST_ITEMS_LIST, payload1);
    const myItemsList = computed(() => {
      return store.getters.priceListItemsList;
    });
    store.dispatch(Actions.PRICE_LIST_ITEMS_SELECTED, myItemsList);

    return {
      id,
      myDetails,
    };
  },
});
